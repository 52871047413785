import React from "react"
import Layout from "../components/layout"

const SignUpPage = ({ location }) => {
  const state = location.state ? location.state : {}
  if (state.pricing) {
    return (
      <Layout>
        <div className="h-screen gradient py-24">
          <h2 className="text-center">14-day free trial</h2>
          <p className="text-center">
            Fill in the form bellow and get a 14 day free trial as soon as we go
            live.
          </p>
          <form
            className="w-2/3 max-w-lg mx-auto shadow-custom p-8 rounded bg-white"
            name="signup-pricing"
            method="POST"
            //@ts-ignore
            data-netlify="true"
            action="/"
          >
            <input
              type="hidden"
              className="hidden"
              name="form-name"
              value="signup-pricing"
            />
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Name
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                  placeholder="Jane Doe"
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Job Title <span className="lowercase">(if any)</span>
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-last-name"
                  type="text"
                  placeholder="Data Analyst"
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="email"
                >
                  E-mail
                </label>
                <input
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  type="text"
                  placeholder="jane.doe@email.com"
                />
                <p className="text-red-500 text-xs italic">
                  Please fill out this field.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-2">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-plan"
                >
                  Plan
                </label>
                <div className="relative">
                  <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-plan"
                  >
                    <option>Basic</option>
                    <option>Advanced</option>
                    <option>Premium</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <input
              className="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="submit"
              value="Sign Up"
            />
          </form>
        </div>
      </Layout>
    )
  }

  if (state.trial) {
    return (
      <Layout>
        <div className="h-screen background py-24">
          <form
            className="w-1/2 max-w-xs mx-auto shadow-custom p-8 rounded bg-white"
            method="POST"
            action="/"
            name="trial"
            data-netlify="true"
          >
            <input
              type="hidden"
              className="hidden"
              name="form-name"
              value="trial"
            />
            <h2>Free Trial</h2>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                for="email"
              >
                E-mail
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="jane.doe@mail.com"
              />
            </div>
            <div className="flex items-center justify-between">
              <input
                className="items-center shadow justify-center px-2 py-1 border border-transparent text-base leading-6 rounded text-white bg-light-blue hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2"
                type="submit"
                value="Send"
              />
            </div>
          </form>
        </div>
      </Layout>
    )
  }

  if (state.contact) {
    return (
      <Layout>
        <h2>Get In Touch</h2>
        <form action="POST" name="contact" data-netlify="true">
          <input
            type="hidden"
            className="hidden"
            name="form-name"
            value="contact"
          />
          <label>
            Name:
            <input type="text" placeholder="Jane Doe" name="name" />
          </label>
          <label>
            E-mail:
            <input type="text" placeholder="jane.doe@mail.com" name="email" />
          </label>
          <label>
            Content<textarea name="content"></textarea>
          </label>
          <input type="submit" value="Send" />
        </form>
      </Layout>
    )
  }

  if (state.learnMore) {
    return (
      <Layout>
        <div className="h-screen gradient py-24">
          <form
            className="w-1/2 max-w-xs mx-auto shadow-custom p-8 rounded bg-white"
            name="learn-more"
            method="POST"
            //@ts-ignore
            data-netlify="true"
          >
            <input
              type="hidden"
              className="hidden"
              name="form-name"
              value="learn-more"
            />
            <h2>Learn More</h2>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                for="email"
              >
                E-mail
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="jane.doe@mail.com"
              />
            </div>
            <div className="flex items-center justify-between">
              <input
                className="items-center shadow justify-center px-2 py-1 border border-transparent text-base leading-6 rounded text-white bg-light-blue hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-1 md:text-lg md:px-2"
                type="submit"
                value="Send"
              />
            </div>
          </form>
        </div>
      </Layout>
    )
  }
  return (
    <Layout>
      <h2>Signup page</h2>
      <form></form>
    </Layout>
  )
}

export default SignUpPage
